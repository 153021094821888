import { createRouter, createWebHashHistory } from "vue-router";
import HomeViewVn from "@/views/vi/HomeView.vue";
import DetailViewVn from "@/views/vi/DetailView.vue";
import HomeViewEn from "@/views/en/HomeViewEn.vue";
import DetailViewEn from "@/views/en/DetailViewEn.vue";
import HomeViewFr from "@/views/fr/HomeViewFr.vue";
import DetailViewFr from "@/views/fr/DetailViewFr.vue";
const routes = [
    {
        path: "/",
        name: "home_vi",
        component: HomeViewVn,
    },
    {
        path: "/vi/detail/:id",
        name: "detail_vi",
        component: DetailViewVn,
    },
    {
        path: "/en",
        name: "home_en",
        component: HomeViewEn,
    },
    {
        path: "/en/detail/:id",
        name: "detail_en",
        component: DetailViewEn,
    },
    {
        path: "/fr",
        name: "home_fr",
        component: HomeViewFr,
    },
    {
        path: "/fr/detail/:id",
        name: "detail_fr",
        component: DetailViewFr,
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

export default router;
