import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.css';
import { createDeviceDetector } from "next-vue-device-detector";
export const device = createDeviceDetector()

const app = createApp(App);



app.mixin({
    data() {
        return {
            baseImg: process.env.VUE_APP_BASE_ASSETS,
            IMAGE_API_URL: process.env.VUE_APP_API_URL,

        }
    }
});


app.use(router).use(device)
    .mount('#app')
