<template>
    <div class="  w-full flex flex-row items-center py-3 pt-2 bg-nav "
   >
        <div class=" cursor-pointer flex flex-row items-left px-5" @click="openHomePage()">
            <img src="~@/assets/LOGOBAOTANGDIENBIENPHU.png" alt="Your Company" width="80" />

        </div>
        <div class="center px-5 w-11/12">
            <p class="text-white  xl:text-4xl sm:text-xl font-sans font-semibold"
                style="text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);">
                LOGICIEL DE PROMOTION D'ARTEFACTS</p>

        </div>
    </div>
</template>


<script>

export default {
    name: 'Autoplay',
    components: {
        
        
    },
    data() {
        return {
        };
    },
    methods: {
        openHomePage() {
            const route = this.$router.resolve({
                name: "home_fr",
            });
            window.open(route.href, "_parent");
        },
    },

}
</script>
<style>
.bg-nav{
      background-image: url("@/assets/thanh.jpg");
  
      background-size: cover;
    }
</style>