<template>
  <div>
    <div v-if="this.$device.mobile || this.$device.ipad">
      <NavbarMobile></NavbarMobile>
      <div class="bg-qbdb-mb  ">

<Explore></Explore>
</div>
    </div>
    <div v-else>
      <NavbarPC></NavbarPC>
      <div class="bg-qbdb">

        <Explore></Explore>
      </div>
    </div>


  </div>
</template>

<script>
import NavbarPC from '@/components/vi/NavbarPC.vue';
import NavbarMobile from '@/components/vi/NavbarMobile.vue';
import Explore from '@/components/vi/Explore.vue'
import apiCalls from '@/config/api';
export default {
  name: 'Autoplay',
  components: {
    NavbarPC,
    Explore,
    NavbarMobile
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },


}
</script>

<style lang="scss">
.bg-qbdb-detail {
  background-image: url("@/assets/nen01.jpg");
  height: calc(100vh - 95px);
  background-size: cover;
}
.ivu-modal-content {
  position: relative;
  background-color: #00261B95 !important;
  border: 0;
  border-radius: 0px !important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
}


.ivu-icon {
  display: none !important;
  font-family: Ionicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: optimizeLegibility;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -0.125em;
  text-align: center;
}

[type=button],
[type=reset],
[type=submit],
button {
  background-color: none !important;
  ;
  background-image: none;
}
</style>
