<template>

    <div class="overflow-hidden"  v-if="this.$device.mobile || this.$device.ipad">
        <NavbarMobile></NavbarMobile>
        <mobile :dataAtifact="dataAtifact"></mobile>
    </div>
    <div  v-else>
        <NavbarPC></NavbarPC>
        <pc  :dataAtifact="dataAtifact"></pc>
    </div>
</template>

<script>
import pc from '@/components/fr/pc.vue';
import mobile from '@/components/fr/mobile.vue';
import apiCalls from '@/config/api';
import NavbarPC from '@/components/fr/NavbarPC.vue';
import NavbarMobile from '@/components/fr/NavbarMobile.vue';
export default {
    components: {
        pc, mobile, NavbarPC,NavbarMobile
    },
    data() {
        return {
            id: this.$route.params.id,
            dataAtifact: {}
        };
    },
    created() {
        this.fetchArtifact();
    },
    methods: {
        fetchArtifact() {
            apiCalls.getArtifact(this.id)
                .then(response => {
                    this.dataAtifact = response;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }
};
</script>
