<template>
    <div v-if="show" class="fixed inset-0 flex items-center justify-center bg-white ">
      <!-- <div class="bg-white rounded-lg p-6 max-w-md"> -->
        <button @click="closeModal" class="absolute top-4 right-4 text-black hover:text-[#046e4f]  bg-white px-2 py-2 rounded-xl">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <iframe :src="link" class="w-screen  h-screen"></iframe>
      </div>
    <!-- </div> -->
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your modal styles here */
  </style>
  