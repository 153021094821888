<template>
    <div class="w-full  flex flex-row items-center py-5 bg-navmb">
        <div class="flex flex-row items-left px-5" @click="openHomePage()">
            <img src="~@/assets/LOGOBAOTANGDIENBIENPHU.png" alt="Your Company" width="80" />

        </div>
        <div class="center title">
            <p class="text-white text-xs font-sans font-semibold"
                style="text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);">
                LOGICIEL DE PROMOTION D'ARTEFACTS</p>

        </div>
    </div>
</template>


<script>

export default {
    name: 'Autoplay',
    components: {
        
        
    },
    data() {
        return {
        };
    },
    methods: {
        openHomePage() {
            const route = this.$router.resolve({
                name: "home_fr",
            });
            window.open(route.href, "_parent");
        },
    },

}
</script>
<style>
.bg-navmb{
      background-image: url("@/assets/thanhmobie.jpg");
  
      background-size: cover;
    }
@media (max-width: 360px) {
  .title p {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
</style>