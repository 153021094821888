<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">

  <div class="h-screen flex flex-col bg-qbdb-mb absolute">
    <div class=" flex flex-col  ">
      <div class="flex flex-col mx-5 bg-[#ffffff50]  h-screen">
        <img src="~@/assets/left-arrows.png" alt="Your Company" width="30" class="top-8 left-8 absolute"
          @click="openHomePage" />
        <img :src="img" class="rounded-3xl	 ">
        <div
          class="text-center items-center text-black text-lg font-sans bg-white rounded-t-3xl absolute bottom-0 mr-5">
          <div>
            <p class="uppercase text-2xl font-bold mt-5">{{ dataAtifact.name_vi }}</p>
            <p v-html="dataAtifact.des_vi" class="text-left mx-10 mt-5 overflow-auto" style="height: 250px;"></p>

          </div>
          <button @click="open3D"
            class="text-white bg-gradient-to-r from-green-500 to-green-700 px-24 py-2 mt-4 rounded-xl ">Xem 3D</button>
          <button @click="openAR"
            class="text-white bg-gradient-to-r from-green-500 to-green-700 px-16 py-2 my-6 rounded-xl ">Trải nghiệm AR</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <Modal :show="showModal" @close="showModal = false" :link="dataAtifact.link_3d" /> -->
  <!-- <Modal :show="showModalAR" @close="showModalAR = false" :link="dataAtifact.link_ar" /> -->
  </meta>
</template>

<script>
import Navbar from './NavbarPC.vue';
import Modal from './Modal.vue';

export default {
  components: {
    Navbar,
    Modal, // Include the modal component
  },
  props: {
    dataAtifact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false, // Initially modal is hidden
      // showModalAR: false, // Initially modal is hidden

    };
  },
  computed: {
    img() {
      return `https://dienbien.online${this.dataAtifact.thumb}`;
    },
  },
  methods: {
    open3D() {
      // this.showModal = true; // Show modal when button is clicked
      window.open(this.dataAtifact.link_3d, '_blank');

    },
    openAR() {
      // this.showModalAR = true; // Show modal when button is clicked
      window.open(this.dataAtifact.link_ar, '_blank');

    },
    openHomePage() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss">
.btn-close-modal {
  @apply absolute top-4 right-4 z-50 bg-teal-800 border-none rounded-md p-2;

  img {
    @apply w-5 h-5;
  }
}

.bg-qbdb-mb {
  background-image: url("@/assets/nenmobie.jpg");
  background-size: cover;

}
</style>
