<template>

    <div class=""  @click="openDetailPage">
      <div class="cursor-pointer flex flex-col bg-white rounded-2xl">
        <div>
          <img :src="img"  :alt="this.dataArtifact.name_fr" class="mx-auto" />
        </div>
        <div class="bg-[#71705C] rounded-b-2xl">
          <p class="text-center  font-[500] text-white	py-5 md:text-sm  xl:text-lg  font-sans">
            {{ desc }}
          </p>
        </div>

      </div>

    </div>

</template>

<script>
export default {
  props: {
    dataArtifact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },

  computed: {

    img() {
      return `https://dienbien.online${this.dataArtifact.thumb}`;
    },
    desc() {
      return this.dataArtifact.name_fr;
    },
    IdArtifact() {
      
      return this.dataArtifact.id;
    },
  },
  methods: {
    openDetailPage() {
      this.$router.push({
                name: 'detail_fr',
                params: {
                  id: this.IdArtifact
                }
            });
    },
  }
};
</script>
