<template>

  <div
    class=" w-full min-h-fit p-10 md:px-20 grid grid-cols-2 gap-x-6 gap-y-6 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3 lg:gap-8 xl:grid-cols-5 xl:gap-10 mx-auto">

    <Card v-for="exhibit in DataTest" :key="exhibit.id" :dataArtifact="exhibit" />
  </div>
</template>

<script>
import Card from "@/components/en/Card.vue";
// import { DataTest } from "@/config/DataTest.js";
import apiCalls from "@/config/api";
export default {
  data() {
    return {
      DataTest: []
    };
  },
  created() {
    this.handleGetAllArtifacts()
  },
  methods: {
    handleGetAllArtifacts() {
      apiCalls.getAllArtifacts()
        .then(response => {
          this.DataTest = response;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  },
  components: {
    Card,
  },
};
</script>
