<template>
  <div class=" flex flex-col justify-center items-center bg-qbdb-detail">
    <div class="flex flex-row justify-center items-center ">
      <div class="flex flex-col justify-center w-1/2">
        <img src="~@/assets/left-arrows.png" alt="Your Company" width="30" class="mb-5" @click="openHomePage" />
        <img :src="img" class="w-3/4 bg-[#ffffff50] rounded-xl">
      </div>
      <div class="text-left items-start text-white text-lg w-1/3 font-sans">
          <p class="uppercase text-4xl font-bold py-8">{{ dataAtifact.name_en }}</p>
          <p v-html="dataAtifact.des_en"></p>
        <button @click="open3D"
          class="text-white bg-gradient-to-r from-green-500 to-green-700 px-20 py-2 rounded-md mt-8">Watch 3D</button>
      </div>
    </div>
  </div>
  <Modal :show="showModal" @close="showModal = false" :link="dataAtifact.link_3d" />
</template>

<script>
import Navbar from './NavbarPC.vue';
import Modal from './Modal.vue'; // Import your modal component here

export default {
  components: {
    Navbar,
    Modal, // Include the modal component
  },
  props: {
    dataAtifact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false, // Initially modal is hidden
    };
  },
  computed: {
    img() {
      return `https://dienbien.online${this.dataAtifact.thumb}`;
    },
  },
  methods: {
    open3D() {
      this.showModal = true; // Show modal when button is clicked
    },
    openHomePage() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss">
.btn-close-modal {
  @apply absolute top-4 right-4 z-50 bg-teal-800 border-none rounded-md p-2;

  img {
    @apply w-5 h-5;
  }
}

.bg-qbdb {
  background-image: url("@/assets/nen01.jpg");
  // height: calc(100vh - 95px);

  background-size: cover;
}
</style>
